import {Box, Card, CustomModalLayout, Modal} from '@wix/design-system';
import React, {CSSProperties, useRef, useState} from 'react';
import QRCode from 'qrcode.react';
import saveAs from 'file-saver';
import DropdownButton from "./DropdownButton";

const QrCodeModal: React.FC<{ shortUrl: string, onClose: () => void }> = ({ shortUrl, onClose }) => {

  const [showModal, setShowModal] = useState(true);
  const qrRef = useRef<HTMLDivElement>(null);

  const qrCodeContainerStyle = {
      border: '1px solid rgb(56, 153, 237))',
      boxShadow: '0 0 8px rgb(170, 219, 252)',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  }

  const qrCodeStyle: CSSProperties = {
      width: '100%',
      height: '100%'
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  };

  const handleDownloadQrCode = async (format: string) => {
      if (!qrRef.current) return;

      const svgElement = qrRef.current.querySelector('svg');
      if (!svgElement) return;

      // Serialize SVG to string
      const svgData = new XMLSerializer().serializeToString(svgElement);

      if (format === 'svg') {
          const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
          saveAs(svgBlob, `tnlnk_${shortUrl.split('/')[3]}.${format}`);
      } else {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const svgSize = svgElement.getBoundingClientRect();
          canvas.width = svgSize.width;
          canvas.height = svgSize.height;

          const img = new Image();
          img.onload = () => {
              ctx?.drawImage(img, 0, 0);
              canvas.toBlob(blob => {
                  if (blob) saveAs(blob, `tnlnk_${shortUrl.split('/')[3]}.${format}`);
              }, format === 'png' ? 'image/png' : 'image/jpeg');
          };
          img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
      }
  };

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">
          <CustomModalLayout title="QR-code"
                             onCloseButtonClick={handleClose}
                             maxHeight="fit-content"
                             width="42%"
                             content={
                                 <Card>
                                     <Box width="100%" height="100%" minWidth="512px" minHeight="512px" marginBottom="SP2">
                                         <div ref={qrRef} style={qrCodeContainerStyle}>
                                             <QRCode value={shortUrl + "?qr=true"}
                                                     size={512}
                                                     level='L'
                                                     includeMargin={true}
                                                     renderAs="svg"
                                                     style={qrCodeStyle}
                                             />
                                         </div>
                                     </Box>
                                 </Card>
                             }
                             sideActions={
                                 <Box direction="horizontal" align="center" verticalAlign="middle">
                                    <DropdownButton options={["png", "svg", "jpg"]}
                                                    onClick={(option) => handleDownloadQrCode(option)}
                                    />
                                 </Box>
                             }
          />
      </Modal>
  );
};

export default QrCodeModal;
