import React from "react";
import {InstanceInfoResponse, VendorProductId} from "../models/models";

const UpgradeTooltipMessage: React.FC<{instanceInfo: InstanceInfoResponse, requiredVendorProductIds: VendorProductId[]}> =
    ({instanceInfo, requiredVendorProductIds}) => {

  if (!requiredVendorProductIds.includes(instanceInfo.vendorProductId)) {
      const upgradeUrl = `https://manage.wix.com/app-pricing-plans/${instanceInfo.appId}/plan?meta-site-id=${instanceInfo.siteId}&origin=in_app_dashboard`
      return <a href={upgradeUrl} target="_blank" rel="noopener noreferrer"><u>Upgrade for more.</u></a>
  }
  return null
};

export default UpgradeTooltipMessage;