import axios from 'axios';

export const generateQrCode = async (shortLinkId: number, queryParams: URLSearchParams) => {
    try {
        return await axios.patch(
            `${process.env.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/qr/${shortLinkId}`,
            {},
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            });
    } catch (e) {
        throw e;
    }
}
