import {Button, Card, Text} from "@wix/design-system";
import React, {useEffect, useState} from "react";
import {VendorProductId} from "../models/models";

const UpgradeBanner: React.FC<{ appId: string, siteId: string, vendorProductId: VendorProductId }> =
    ({appId, siteId, vendorProductId}) => {

  const [title, setTitle] = useState("Title");
  const [description, setDescription] = useState("description");

  useEffect(() => {
      setContent(vendorProductId);
      }, []);

  const setContent = (vendorProductId: VendorProductId) => {
      switch (vendorProductId) {
          case VendorProductId.NOT_PURCHASED:
              setTitle("Your premium access no longer active")
              setDescription("Upgrade to our premium plan now to keep your shortened links active and enjoy all the exclusive TinyLinks features")
              return
          case VendorProductId.FREE_TRIAL:
              setTitle("Continue using TinyLinks after your free trial?")
              setDescription("Upgrade to maintain your premium experience")
              return;
          case VendorProductId.basic:
              setTitle("Upgrade to Business Plan")
              setDescription("Unlock 90-day analytics and 100 links per month!")
              return;
          case VendorProductId.business:
              setTitle("Upgrade to Ultimate Plan")
              setDescription("Get 1-year analytics and 500 links per month!")
              return;
      }
    }

    const handleButtonClick = (appId: string, siteId: string) => {
      const url = `https://manage.wix.com/app-pricing-plans/${appId}/plan?meta-site-id=${siteId}&origin=in_app_dashboard`
      window.open(url, '_blank');
    }

    return <Card showShadow>
                <Card.Header title={<Text tagName="div" weight="bold" size="small">{title}</Text>}
                             subtitle={<Text size="tiny" secondary>{description}</Text>}
                             suffix={<Button skin="premium" size="medium" onClick={() => handleButtonClick(appId, siteId)}>Upgrade</Button>}
                />
    </Card>
}

export default UpgradeBanner;
