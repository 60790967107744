import {DomainResponse, VerificationStatus} from "../models/models";
import axios from "axios";

export const getDomains = async (queryParams: URLSearchParams, verificationStatuses: Set<VerificationStatus> = new Set()) => {
    try {
        verificationStatuses.forEach((verificationStatus) => {
            queryParams.append('verificationStatus', verificationStatus);
        });

        return await axios.get<DomainResponse[]>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/domain`,
            { params: queryParams }
        );
    } catch (e) {
        throw e;
    }
}

export const addDomain = async (domainName: string, queryParams: URLSearchParams) => {
    try {
        return await axios.post<DomainResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/domain?domainName=${domainName}`,
            {},
            { params: { instance: queryParams.get("instance") } }
        )
    } catch (e) {
        throw e;
    }
}

export const restartDomainVerification = async (domainId: number, queryParams: URLSearchParams) => {
    try {
        return await axios.patch<DomainResponse>(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/domain/${domainId}`,
            {},
            { params: { instance: queryParams.get("instance") } }
        )
    } catch (e) {
        throw e;
    }
}

export const deleteDomain = async (domainId: number, queryParams: URLSearchParams) => {
    try {
        return await axios.delete(
            `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/domain/${domainId}`,
            { params: queryParams }
        )
    } catch (e) {
        throw e;
    }
}
