import {
    CustomModalLayout,
    Modal,
    Text,
} from '@wix/design-system';
import React, {useState} from 'react';
import {generateQrCode} from "../services/qr-code-client";
import {useSearchParams} from "react-router-dom";

const QrCodeConfirmationModal: React.FC<{ linkId: number, onClose : () => void, onSubmit : () => void }> =
    ({ linkId, onClose, onSubmit }) => {

  const [showModal, setShowModal] = useState(true);
  const [searchParams] = useSearchParams();

  const handleSubmit = (linkId: number) => {
      generateQrCode(linkId, searchParams)
          .then(() => onSubmit())
          .finally(() => {
              setShowModal(false);
              onClose();
          })
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">

          <CustomModalLayout primaryButtonText="Sumbit"
                             primaryButtonOnClick={() => handleSubmit(linkId)}
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             title="Generate new QR-code?"
                             overflowY="none"
                             content={
                                 <Text>
                                     Do you want to generate a QR-code for the specified link?
                                     <br/>
                                     Submit to proceed.
                                 </Text>
                             }
          />
      </Modal>
  );
};

export default QrCodeConfirmationModal;
