import React, {
    useEffect,
    useState,
} from 'react';
import DatePicker from 'react-datepicker';
import {AggregationPeriod} from '../models/models';
import { WixBooking } from '@wix/wix-ui-icons-common';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/datepicker-styles.css'

const DateRangePickerComponent: React.FC<{ startDate: Date, endDate: Date, minDate: Date, maxDate: Date, aggregationPeriod: AggregationPeriod, onClose: (dates: Date[]) => void }> =
    ({ startDate, endDate, minDate, maxDate, aggregationPeriod, onClose }) => {

  const [calendarStartDate, setCalendarStartDate] = useState<Date>(startDate);
  const [calendarEndDate, setCalendarEndDate] = useState<Date>(endDate);

  const DATE_FORMAT = 'MMM dd, yyyy';
  const MONTH_FORMAT = 'MMM yyyy';

  useEffect(() => {
      setCalendarStartDate(startDate);
      setCalendarEndDate(endDate);
  }, [startDate, endDate]);

  const onChange = (dates: Date[]) => {
      const [start, end] = dates;
      setCalendarStartDate(start);
      setCalendarEndDate(end);
      onClose(dates);
  };

  switch (aggregationPeriod) {
      case AggregationPeriod.Daily:
          return (
              <DatePicker showIcon
                          icon={<WixBooking color={"rgb(50, 107, 246)"} />}
                          toggleCalendarOnIconClick
                          selectsRange
                          selected={calendarStartDate}
                          startDate={calendarStartDate}
                          endDate={calendarEndDate}
                          minDate={minDate}
                          maxDate={maxDate}
                          dateFormat={DATE_FORMAT}
                          onChange={onChange}
              />);
      case AggregationPeriod.Monthly:
          return (
              <DatePicker showIcon
                          icon={<WixBooking color={"rgb(50, 107, 246)"} />}
                          toggleCalendarOnIconClick
                          selectsRange
                          selected={calendarStartDate}
                          startDate={calendarStartDate}
                          endDate={calendarEndDate}
                          minDate={minDate}
                          maxDate={maxDate}
                          dateFormat={MONTH_FORMAT}
                          showMonthYearPicker
                          onChange={onChange}
              />);
    }
}

export default DateRangePickerComponent;
