import axios from 'axios';
import {
    ViewAnalyticsRequest,
    ViewAnalyticsResponse,
} from '../models/models';

export const getViewAnalytics = async (analyticsRequest: ViewAnalyticsRequest, queryParams: URLSearchParams) => {
    try {
        return await axios.get<ViewAnalyticsResponse>(
            `${process.env.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/analytics/views`,
            { params:
                    {
                        instance: queryParams.get("instance"),
                        linkId: analyticsRequest.linkId,
                        startDate: analyticsRequest.startDate,
                        endDate: analyticsRequest.endDate,
                        timeZone: analyticsRequest.timeZone,
                        aggregationPeriod: analyticsRequest.aggregationPeriod
                    }
            }
        );
    } catch (e) {
        throw e;
    }
}
