import * as yup from "yup";

export const cutLinkSchema = yup.object().shape({
    url: yup.string()
        .url("Invalid URL format")
        .max(2000, "URL should be no more than 2000 characters")
        .required("URL shouldn't be empty"),
    title: yup.string()
        .optional()
        .trim("Short link title can't include leading and trailing spaces")
        .min(1, "Title shouldn't be empty")
        .max(2000, "Link title should be no more than 2000 characters"),
    customShortUrlCode: yup.string()
        .optional()
        .trim("Custom short URL can't include leading and trailing spaces")
        .min(3, "Custom short URL should include al least 3 characters")
        .max(150, "Custom short URL should be no more than 150 characters")
        .matches(/^[A-Za-z0-9-_]+$/, "Custom short URL can only contain letters, numbers, underscores and dashes")
});
