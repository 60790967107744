import {
    Box,
    Text
} from '@wix/design-system';
import React from 'react';
import {ShortLinkResponse, VerificationStatus} from '../models/models';

const ShortLinkListItem: React.FC<{shortLink: ShortLinkResponse}> = ({ shortLink}) => {

  const schema = 'https://';
  const shortUrl = `${shortLink.domain.domainName}/${shortLink.shortUrlCode}`;
  const shortUrlWithSchema = schema + shortUrl;
  const isLinkDisabled = shortLink.domain.verificationStatus !== VerificationStatus.Verified;

  return (
      <Box direction="vertical">
          <Text ellipsis weight="normal">{shortLink.title}</Text>
          <Text size="small" secondary>
              <a href={shortUrlWithSchema}
                 target="_blank"
                 rel="noopener noreferrer"
                 style={{ color: isLinkDisabled ? "#868AA5" : "rgb(56, 153, 237)",
                          pointerEvents: isLinkDisabled ? "none" : "auto",
                          fontSize: '16px'
                        }}
                 onClick={(e) => {
                     if (isLinkDisabled) { e.preventDefault(); }
                 }}
              >
                  {shortUrl}
              </a>
          </Text>
      </Box>
  );
};

export default ShortLinkListItem;
