import {
    Box,
    CustomModalLayout,
    Dropdown,
    DropdownLayoutValueOption,
    FormField,
    Heading,
    Input,
    Modal,
    Text,
    ToggleSwitch,
    Tooltip,
} from '@wix/design-system';
import {StatusType} from '@wix/design-system/dist/types/FormField';
import React, {ChangeEvent, useEffect, useState,} from 'react';
import {
    DomainResponse,
    InstanceInfoResponse,
    ShortLinkRequest,
    VendorProductId,
    VerificationStatus
} from '../models/models';
import {cutLink} from '../services/short-link-client';
import {cutLinkSchema} from '../validations/CutLinkValidation';
import {useSearchParams} from "react-router-dom";
import {ValidationError} from 'yup';
import {InfoCircleSmall, LockLocked} from "@wix/wix-ui-icons-common";
import {getDomains} from "../services/domain-client";
import UpgradeTooltipMessage from "./UpgradeTooltipMessage";

const CutLink: React.FC<{ instanceInfo: InstanceInfoResponse, onClose : () => void, onSave : () => void }> =
    ({ instanceInfo, onClose, onSave }) => {

  const DEFAULT_DOMAIN_ID: number = -1;

  const [showModal, setShowModal] = useState(true);
  const [urlFieldStatus, setUrlFieldStatus] = useState<StatusType>();
  const [urlStatusMessage, setUrlStatusMessage] = useState("");
  const [titleFieldStatus, setTitleFieldStatus] = useState<StatusType>();
  const [titleStatusMessage, setTitleStatusMessage] = useState("");
  const [customShortUrlFieldStatus, setCustomShortUrlFieldStatus] = useState<StatusType>();
  const [customShortUrlFieldStatusMessage, setCustomShortUrlFieldStatusMessage] = useState("");
  const [domains, setDomains] = useState<DomainResponse[]>([]);
  const [selectedDomainOptionId, setSelectedDomainOptionId] = useState<number>(DEFAULT_DOMAIN_ID);
  const [cutLinkRequest, setCutLinkRequest] = useState<ShortLinkRequest>(
          {
              longUrl: '',
              generateQrCode: false,
              domainId: DEFAULT_DOMAIN_ID
          });
  const [searchParams] = useSearchParams();

  useEffect(() => {
      getDomains(searchParams, new Set([VerificationStatus.Verified]))
          .then(
              res => {
                  const domains = res.data;
                  setDomains(domains);
                  setSelectedDomainOptionId(domains.length === 0 ? DEFAULT_DOMAIN_ID : domains[domains.length - 1].id);
                  setCutLinkRequest({
                      longUrl: '',
                      generateQrCode: false,
                      domainId: domains.length === 0 ? DEFAULT_DOMAIN_ID : domains[domains.length - 1].id
                  })
              })
  }, []);

  const dropdownOptions = () => {
      const customDomainOptions = domains.map((domain) => ({
          id: domain.id,
          value: domain.domainName,
          label: domain.domainName
      }));

      customDomainOptions.push({
          id: DEFAULT_DOMAIN_ID,
          value: 'tnlnk.co',
          label: 'tnlnk.co'
      });

      return customDomainOptions;
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>, inputName: string) => {
      const newValue = event.target.value;
      setCutLinkRequest(prevState => ({
          ...prevState,
          [inputName]: newValue.trim()
      }));
  }

  const handleCustomDomainChange = (valueOptions: DropdownLayoutValueOption) => {
      const domainId = Number(valueOptions.id);
      setSelectedDomainOptionId(domainId);

      setCutLinkRequest(prevState => ({...prevState, domainId: Number(domainId)}));
  }

  const handleToggleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
      setCutLinkRequest(prevState => ({...prevState, generateQrCode: event.target.checked}));
  }

  const setInputFieldError = (field: string | undefined, message: string) => {
      if (field === 'url') {
          setUrlFieldStatus("error")
          setUrlStatusMessage(message)
      } else if (field === 'title') {
          setTitleFieldStatus("error")
          setTitleStatusMessage(message)
      } else if (field === 'customShortUrlCode') {
          setCustomShortUrlFieldStatus('error')
          setCustomShortUrlFieldStatusMessage(message)
      }
  }

  const handleSave = async () => {
      try {
          // Validate input
          cutLinkSchema.validateSync({
              url: cutLinkRequest.longUrl,
              title: cutLinkRequest.title,
              customShortUrlCode: cutLinkRequest.customShortUrlCode,
          });

          try {
              // Call the cutLink function and await its completion
              await cutLink(cutLinkRequest, searchParams);

              // If successful, proceed with saving and closing
              onSave();
              setShowModal(false);
              onClose();
          } catch (error: any) {
              // Handle specific error codes
              if (error.response && error.response.status === 409) {
                  setInputFieldError('customShortUrlCode', 'Provided short link already exists');
              }
          }
      } catch (err: any) {
          // Handle validation errors
          if (err instanceof ValidationError) {
              setInputFieldError(err.path, err.message);
          }
      }
  };

  const handleClose = () => {
      setShowModal(false);
      onClose();
  }

  const isDomainDisabled = instanceInfo.vendorProductId === VendorProductId.basic
      || instanceInfo.vendorProductId === VendorProductId.FREE_TRIAL
      || instanceInfo.vendorProductId === VendorProductId.NOT_PURCHASED

  return (
      <Modal isOpen={showModal}
             onRequestClose={handleClose}
             shouldCloseOnOverlayClick
             screen="desktop">

          <CustomModalLayout primaryButtonText="Shorten URL"
                             primaryButtonOnClick={handleSave}
                             secondaryButtonText="Cancel"
                             secondaryButtonOnClick={handleClose}
                             onCloseButtonClick={handleClose}
                             title="New Short Link"
                             overflowY="none"
                             content={
                                 <>
                                     <FormField status={urlFieldStatus} statusMessage={urlStatusMessage}>
                                         <Text>Destination URL</Text>
                                         <Input size="medium"
                                                placeholder="http(s)://"
                                                onChange={event => handleInputChange(event, 'longUrl')}/>
                                     </FormField>
                                     <Box direction='horizontal' paddingTop="small" gap="tiny">
                                         <Text size="small">{instanceInfo.availableLinks} more links available this month</Text>
                                         <Tooltip inline
                                                  content={
                                                      <Text size="small" light={true}>
                                                          Your current plan includes {instanceInfo.linksLimit} links per month. {<UpgradeTooltipMessage instanceInfo={instanceInfo} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                      </Text>
                                         }>
                                             <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                         </Tooltip>
                                     </Box>
                                     <p/>
                                     <FormField status={titleFieldStatus}
                                                statusMessage={titleStatusMessage}
                                                required={false}>
                                         <Text>Title (optional)</Text>
                                         <Input size="medium"
                                                placeholder="Title"
                                                onChange={event => handleInputChange(event, 'title')}/>
                                     </FormField>
                                     <Box direction="vertical" paddingTop="medium" paddingBottom="small">
                                         <Heading size="small">Customize your link</Heading>
                                     </Box>
                                     <Box direction='horizontal'>
                                         <FormField>
                                             <Box direction='horizontal'>
                                                 <Text>Domain</Text>
                                                 { isDomainDisabled &&
                                                     <Tooltip inline
                                                              content={
                                                                  <Text size="small" light={true}>
                                                                      Branded links aren't available on your current plan. {<UpgradeTooltipMessage instanceInfo={instanceInfo} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                                  </Text>
                                                     }>
                                                         <LockLocked color={"rgb(56, 153, 237)"} />
                                                     </Tooltip> }
                                             </Box>
                                             <Dropdown options={dropdownOptions()}
                                                       selectedId={selectedDomainOptionId}
                                                       disabled={isDomainDisabled}
                                                       onSelect={(option) => handleCustomDomainChange(option)}/>
                                         </FormField>

                                         <Box paddingTop="SP4" paddingLeft="tiny" paddingRight="tiny">
                                             <h6 style={{
                                                 fontSize: '30px',
                                                 fontWeight: "normal",
                                                 margin: "0px"
                                             }}>/</h6>
                                         </Box>

                                         <FormField status={customShortUrlFieldStatus}
                                                    statusMessage={customShortUrlFieldStatusMessage}
                                                    required={false}>
                                             <Text>Custom back-half (optional)</Text>
                                             <Input size="medium"
                                                    placeholder="your-custom-back-half"
                                                    disabled={instanceInfo.availableCustomShortUrlCodes <= 0}
                                                    onChange={event => handleInputChange(event, 'customShortUrlCode')}/>
                                         </FormField>
                                     </Box>
                                     <Box direction='horizontal' paddingTop="small" gap="tiny">
                                         <Text size="small"> {instanceInfo.availableCustomShortUrlCodes} more custom back-halves available this month</Text>
                                         <Tooltip inline
                                                  content={
                                                      <Text size="small" light={true}>
                                                          Your current plan includes {instanceInfo.customShortUrlLimit} custom back-halves per month. {<UpgradeTooltipMessage instanceInfo={instanceInfo} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                      </Text>
                                         }>
                                             <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                         </Tooltip>
                                     </Box>
                                     <p/>
                                     <FormField>
                                         <Box direction='horizontal' gap="tiny">
                                             <ToggleSwitch size="medium"
                                                           checked={cutLinkRequest.generateQrCode}
                                                           disabled={instanceInfo.availableQrCodes <= 0}
                                                           onChange={event => handleToggleSwitchChange(event)}/>
                                                 <Text size="small">Generate QR-code, {instanceInfo.availableQrCodes} more available this month</Text>
                                                 <Tooltip inline
                                                          content={
                                                              <Text size="small" light={true}>
                                                                  Your current plan includes {instanceInfo.qrCodesLimit} QR Codes per month. {<UpgradeTooltipMessage instanceInfo={instanceInfo} requiredVendorProductIds={[VendorProductId.ultimate]} />}
                                                              </Text>
                                                          }>
                                                     <InfoCircleSmall color={"rgb(56, 153, 237)"}/>
                                                 </Tooltip>
                                         </Box>
                                     </FormField>
                                 </>
                             }
          />
      </Modal>
  );
};

export default CutLink;
