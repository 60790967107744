import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import TinyLinks from "./components/TinyLinks";
import {useEffect} from "react";

function App() {

  const LOCALHOST = "localhost";
  const INSTANCE_PARAM = 'instance';
  const INSTANCE_VALUE = 'test-instance-value';

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
      if (window.location.hostname === LOCALHOST) {
          const searchParams = new URLSearchParams(location.search);

          if (!searchParams.has(INSTANCE_PARAM)) {
              searchParams.set(INSTANCE_PARAM, INSTANCE_VALUE);  // Add default param 'param=test'
              navigate(`${location.pathname}?${searchParams.toString()}`, {replace: true});
          }
      }
  }, [location, navigate]);

  return (
      <Routes>
          <Route path="/" element={<TinyLinks/>} />
      </Routes>
  );
}

export default App;
