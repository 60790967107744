import {Box, Text} from "@wix/design-system";
import React from "react";

const DomainRestartVerificationInstructions = () => (
    <Box direction="vertical" paddingLeft="1%">
        <Text weight="bold">1. Confirm DNS Configuration</Text>
        <Box direction='vertical' marginTop='1%' marginBottom='3%' marginLeft="3%">
            <Text>Ensure your DNS settings are correct. TinyLinks requires:</Text>
            <Text listStyle='circle'>
                <ul>
                    <li>
                        <strong>A Records</strong> for the domain pointing to <code>67.199.248.12</code>.
                    </li>
                    <li>
                        If there’s only one A record, add a second with the other IP. Remove any A records that point to other IPs.
                    </li>
                </ul>
            </Text>
        </Box>
        <Text weight='bold'>2. Check IP Setup</Text>
        <Box direction='vertical' marginTop='1%' marginBottom='3%' marginLeft="3%">
            <Text>Use tools like <a target="_blank" href="https://www.whatsmydns.net/" rel="noreferrer">whatsmydns.net</a> to verify
                that only TinyLinks IP address is active in your DNS records.
            </Text>
        </Box>
        <Text weight='bold'>3. Avoid Using Your Website’s Domain</Text>
        <Box direction='vertical' marginTop='1%' marginLeft="3%">
            <Text>
                Your TinyLinks domain must be unique. Use a new domain or subdomain, different from your website's. For example:
            </Text>
            <Text listStyle='circle'>
                <ul>
                    <li>
                        Website: <code>www.espn.com</code>
                    </li>
                    <li>
                        TinyLinks: <code>es.pn</code>
                    </li>
                </ul>
            </Text>
        </Box>
    </Box>
);

export default DomainRestartVerificationInstructions;