import * as yup from "yup";

export const updateLinkSchema = yup.object().shape({
    longUrl: yup.string()
        .optional()
        .url("Invalid URL format")
        .max(2000, "URL should be no more than 2000 characters"),
    title: yup.string()
        .optional()
        .trim("Short link title can't include leading and trailing spaces")
        .min(1, "Title shouldn't be empty")
        .max(2000, "Link title should be no more than 2000 characters")
});
