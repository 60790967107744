import React from 'react';
import {QR} from "@wix/wix-ui-icons-common";

const QrCodeIcon: React.FC<{isQrCodeGenerated: boolean}> = ({isQrCodeGenerated}) => {

  const iconStyle = {
      border: '1px solid rgb(56, 153, 237))',
      boxShadow: '0 0 4px rgb(170, 219, 252)',
      borderRadius: '50%',
      padding: '5px'
  };

  if (isQrCodeGenerated) {
      return (
          <div style={iconStyle}>
              <QR />
          </div>
      )
  } else {
      return <QR />
  }
};

export default QrCodeIcon;
