import {Box, IconButton, Text, Tooltip} from "@wix/design-system";
import React from "react";
import DomainStatusBadge from "./DomainStatusBadge";
import {VerificationStatus} from "../models/models";
import {handleCopy} from "../services/clipboard-service";
import {DocDuplicate} from "@wix/wix-ui-icons-common";

const DomainSetupInstructions = () => (
    <Box direction="vertical" paddingTop="2%" paddingLeft="2%" paddingBottom="2%">
        <Text size="medium" weight="bold">1. Choose and Purchase a Custom Domain:</Text>
        <Text size="medium" listStyle='circle'>
            <ul>
                <li>
                    <strong>Select a Custom Domain:</strong> Choose a domain that is branded, recognizable, and easy to share.
                    <br/>Ensure the domain is <strong>71 characters</strong> or less, including the dot.
                </li>
                <li>
                    <strong>Purchase the Domain:</strong> If you do not already own a domain, use a reputable domain registrar such
                    as <a target="_blank" href="https://www.godaddy.com/" rel="noreferrer">GoDaddy</a>, <a target="_blank" href="https://www.namecheap.com/" rel="noreferrer">Namecheap</a>,
                    or others to purchase your desired domain. Follow their instructions for completing the purchase.
                </li>
            </ul>
        </Text>

        <Text size="medium" weight="bold">2. Configure DNS Settings:</Text>
        <Text size="medium" listStyle='circle'>
            <ul>
                <li>
                    <strong>Access DNS Management:</strong> Log in to your domain registrar's account
                    and navigate to the <strong>DNS management section</strong>.
                    This section may be labeled as "DNS Configuration," "DNS Records," or "Advanced DNS."
                </li>
                <li>
                    <strong>For a Root Domain (e.g., yourdomain.com):</strong> Create or edit <strong>A Records</strong>.
                    <br/>
                    <strong>Note:</strong> Ensure no other A Records are present for the root domain.
                </li>
            </ul>
        </Text>
        <Text size="medium" indent="5px">
            <table style={{
                border: '1px solid rgb(159, 209, 246)',
                borderCollapse: 'collapse',
                width: '80%',
                marginLeft: '10%'
            }}>
                <thead>
                <tr style={{ borderBottom: '1px solid rgb(159, 209, 246)' }}>
                    <th style={{ border: '1px solid rgb(159, 209, 246)', padding: '6px 26px 6px 6px' }}>Type</th>
                    <th style={{ border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>Name/Host</th>
                    <th style={{ border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>Value/Points To</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>A</td>
                    <td style={{border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>@</td>
                    <td style={{
                        border: '1px solid rgb(159, 209, 246)',
                        padding: '6px',
                        display: 'flex',               // Enable flex layout
                        alignItems: 'center',          // Center align items vertically

                    }}>
                        {window._env_.REACT_APP_TINY_LINKS_IP_ADDRESS}
                        <Tooltip content="Copy">
                            <IconButton
                                skin="light"
                                size="medium"
                                onClick={() => handleCopy(window._env_.REACT_APP_TINY_LINKS_IP_ADDRESS)}
                            >
                                <DocDuplicate/>
                            </IconButton>
                        </Tooltip>
                    </td>
                </tr>
                </tbody>
            </table>
        </Text>
        <Text size="medium" listStyle='circle'>
            <ul>
                <li>
                    <strong>For a Subdomain (e.g., links.yourdomain.com):</strong> Create or edit a <strong>A Record</strong>.
                    <br/>
                    <strong>Note:</strong> Ensure no other A Records are present for the subdomain.
                </li>
            </ul>
        </Text>
        <Text size="medium" listStyle='circle' indent="10px">
            <table style={{
                border: '1px solid rgb(159, 209, 246)',
                borderCollapse: 'collapse',
                width: '80%',
                marginLeft: '10%'
            }}>
                <thead>
                <tr style={{borderBottom: '1px solid rgb(159, 209, 246)'}}>
                    <th style={{border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>Type</th>
                    <th style={{ border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>Name/Host</th>
                    <th style={{ border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>Value/Points To</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>A</td>
                    <td style={{border: '1px solid rgb(159, 209, 246)', padding: '6px' }}>links</td>
                    <td style={{
                        border: '1px solid rgb(159, 209, 246)',
                        padding: '6px',
                        display: 'flex',               // Enable flex layout
                        alignItems: 'center',           // Center align items vertically
                    }}>
                        {window._env_.REACT_APP_TINY_LINKS_IP_ADDRESS}
                        <Tooltip content="Copy">
                            <IconButton
                                skin="light"
                                size="medium"
                                onClick={() => handleCopy(window._env_.REACT_APP_TINY_LINKS_IP_ADDRESS)}
                            >
                                <DocDuplicate/>
                            </IconButton>
                        </Tooltip>
                    </td>
                </tr>
                </tbody>
            </table>
        </Text>
        <br/>
        <Text size="medium" weight="bold">Step 3: Verify the Domain</Text>
        <Text size="medium" listStyle='circle'>
            <ul>
                <li>Enter the domain you set up (e.g., yourdomain.com).</li>
                <li>
                    If you updated the DNS settings correctly, your domain should be connected within <strong>24
                    hours</strong>.
                    <br/>
                    Until then, the status will show as <DomainStatusBadge children={VerificationStatus.InProgress}/>
                    <br/>
                    You can check the status in the custom domain setting.
                </li>
            </ul>
            <br/>
            Once you've completed these steps, you should be able to use your custom domain for shortening links!
            <br/>
            If you encounter any issues, please contact <a href="mailto:support@tiny-links.com">support@tiny-links.com</a> for additional help.
        </Text>
    </Box>
);

export default DomainSetupInstructions;
