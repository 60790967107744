import axios from 'axios';
import {
    ShortLinkDeleteRequest,
    ShortLinkUpdateRequest,
    ShortLinkRequest,
    ShortLinkResponse,
} from '../models/models';
import { parseISO } from "date-fns/esm";

const ISODateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
    return typeof value === "string" && ISODateFormat.test(value);
};

const handleDates = (data: unknown) => {
    if (isIsoDateString(data)) return parseISO(data);
    if (data === null || data === undefined || typeof data !== "object") return data;

    for (const [key, val] of Object.entries(data)) {
        // @ts-expect-error this is a hack to make the type checker happy
        if (isIsoDateString(val)) data[key] = parseISO(val);
        else if (typeof val === "object") handleDates(val);
    }

    return data
};

axios.interceptors.response.use((rep) => {
    handleDates(rep.data);
    return rep;
});

export const getShortLinks = async (queryParams: URLSearchParams) => {
    try {
        return await axios.get<ShortLinkResponse[]>(`${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener`,
            {
                params: {
                    instance: queryParams.get("instance"),
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                }
            })
    } catch (e) {
        throw e;
    }
}

export const cutLink = async (shortLink: ShortLinkRequest, queryParams: URLSearchParams) => {
    try {
        return await axios.post<ShortLinkResponse>(`${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/cut`,
            shortLink,
            {
                params: {
                    instance: queryParams.get("instance")
                }
            })
    } catch (e) {
        throw e;
    }
}

export const updateLink = async (shortLinkUpdateRequest: ShortLinkUpdateRequest, queryParams: URLSearchParams) => {
    try {
        return await axios.patch<ShortLinkResponse>(`${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/update`,
            shortLinkUpdateRequest,
            {
                params: {
                    instance: queryParams.get("instance"),
                }
            })
    } catch (e) {
        throw e;
    }
}

export const deleteShortLinks = async (deleteRequest: ShortLinkDeleteRequest, queryParams: URLSearchParams)=> {
    try {
        return await axios({
            method: 'DELETE',
            url: `${window._env_.REACT_APP_TINY_LINKS_API_BASE_URL}/api/v1/link-shortener/delete`,
            params: {instance: queryParams.get("instance")},
            data: deleteRequest
        });
    } catch (e) {
        throw e;
    }
}

export const deleteShortLinkByShortUrl = async (shortUrl: string, queryParams: URLSearchParams) => {
    const deleteRequest: ShortLinkDeleteRequest = {
        shortUrlCodes: [ shortUrl ]
    }

    return deleteShortLinks(deleteRequest, queryParams);
}
